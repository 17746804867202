import { creationInputAtom } from '@/atoms'
import { T2I_MODEL_VERSION } from '@/constants'
import { CreationModeEnum, ModelVersion } from '@/types'
import { useAtom } from 'jotai'
import { useCallback, useMemo } from 'react'

export default function useModelVersion() {
  const [creationInput, setCreationInput] = useAtom(creationInputAtom)
  const { mode, version } = creationInput

  const availableVersions = useMemo(() => {
    if (CreationModeEnum.CreateImg === mode) {
      return [T2I_MODEL_VERSION]
      // return [ModelVersion.TWO]
    }
    return [CreationModeEnum.CreateHD, CreationModeEnum.AnimateHD].includes(mode as any)
      ? [ModelVersion.ONE, ModelVersion.TWO]
      : [ModelVersion.ONE]
  }, [mode])

  const realVersion = useMemo(() => {
    return availableVersions.includes(version) ? version : availableVersions[0]
  }, [version, availableVersions])

  const setVersion = useCallback(
    (version: ModelVersion) => {
      setCreationInput((prev) => ({
        ...prev,
        version,
      }))
    },
    [setCreationInput],
  )

  return {
    version: realVersion,
    setVersion,
    availableVersions,
  }
}
